import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';

function Footer() {
  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
        
Cortex, the cloud product automating day to day activity like <br /> payroll, HR, activity management, purchase, inventory,store, visitor tracking,<br />  alerts, smart dashboard, transaction, finance, sales, AI and Smart Digital Card Zangie

        </FooterSubHeading>
        <FooterSubText></FooterSubText>
      
      </FooterSubscription>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>About</FooterLinkTitle>
            <FooterLink to='/Aboutus'>About Us</FooterLink>   
            <FooterLink to='/Blog'>Blog</FooterLink>
           
            
            
           
          </FooterLinkItems>
          <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Company</FooterLinkTitle>
                     
            <FooterLink to='/Contact'>Contact Us</FooterLink>
             <FooterLink to='/Recentsuccess'>Recent Success</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Products</FooterLinkTitle>
            <FooterLink  onClick={() => window.open('https://zangie.mounts.in/')}  to='/'>Zangie Card</FooterLink>
            <FooterLink to='/products'>Cloud Product</FooterLink>
            
            
          </FooterLinkItems>
        </FooterLinksWrapper> 
          <FooterLinkItems>
            <FooterLinkTitle>Legal</FooterLinkTitle>
            <FooterLink to='/PrivacyPolicy'>Privacy Policy</FooterLink>
            <FooterLink to='/Terms'>Terms of Service</FooterLink>
            
        
          </FooterLinkItems>
        </FooterLinksWrapper>
      
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
          
            MOUNTS SOFTWARE SERVICE
          </SocialLogo>
          <WebsiteRights>Copyright © 2024 </WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://www.facebook.com/mounts.cortex.7' target='_blank' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href='https://www.instagram.com/mountscortex001/' target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href={
                'https://www.youtube.com/channel/UCBz_FyrB8QeWY1TniHIHagA'
              }
              rel='noopener noreferrer'
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </SocialIconLink>
                      <SocialIconLink href='https://twitter.com/CortexMounts' target='_blank' aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>
                      <SocialIconLink href='https://www.linkedin.com/in/mount-cortex-566a44200/' target='_blank' aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;
