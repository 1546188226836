// ContactUs.js

import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  
  margin: 0 auto;
  padding: 20px;
  background-color: #101522;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Source Sans Pro', sans-serif;
`;

const Card = styled.div`
  background-color: #1c2331;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  h2 {
    color: #fff;
    font-family: cursive;
    margin-bottom: 15px;
  }

  p {
    color: #ccc;
    margin-bottom: 0; 
  }
`;

const ContactUs = () => {
    return (
            
          
        
        <ContactContainer>
            <center><h1 style={{ color: '#fff', fontFamily: 'cursive', marginBottom: 50 }}>Contact Us</h1></center>
            <Card>
                <h2>Address</h2>
                <p>MOUNTS  SOFTWARE SERVICE , WZ-143/4-D, NEW MAHABIR NAGAR, NEW DELHI, West
                    Delhi, Delhi, 110018
 <br />
                    <b style={{ color: '#fff' }}>GSTN NO </b>-  07ASOPC6084A1ZW<br />
                    <b style={{ color: '#fff' }}>Constitution of Business</b> - Proprietorship<br />
                </p>
            </Card>
            <Card>
                <h2>Email</h2>
                <p>rani@mounts.in</p>
            </Card>
            <Card>
                <h2>Phone Number</h2>
                <p>+919978643408</p>
            </Card>
            </ContactContainer>
            
    );
};

export default ContactUs;
